@tailwind base;
@tailwind components;
@tailwind utilities;

.task-list-item::before {
  @apply hidden;
}

.task-list-item {
  @apply list-none;
}

.footnotes {
  @apply mt-12 border-t border-gray-200 pt-8 dark:border-gray-700;
}

.csl-entry {
  @apply my-5;
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.main-post [type='checkbox'] {
  margin: 0 !important;
}

[type='checkbox']:checked,
[type='radio']:checked {
  @apply bg-secondary-500;
}

.main-post ol .task-list-item {
  list-style-type: auto;
}
